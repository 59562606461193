
.project-header {
  padding: 2rem $side-padding;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 1.5rem;
  .project-title {
    grid-column: 1/3;
    text-align: center;
    h1 {
      padding: 0 0 0.5rem 0;
    }
  }
  .project-arrow {
    display: flex;
    position: relative;
    justify-content: center;
    padding-bottom: 0.3rem;
    p {
      color: $blue;
    }
    .arrow {
      width: 20px;
      &.left {
        padding-right: 0.5rem;
      }
      &.right {
        padding-left: 0.5rem;
      }
    }
    &:nth-of-type(2) {
      grid-column-start: 2;
      text-align: right;
    }
  }
}

/* Project Arrow Descriptions */
// .project-arrow.safingWebsite::before {
//   content: "Safing Website";
//   position: absolute;
//   bottom: -23px;
//   font-size: 0.875rem;
//   color: #6E7481;
//   opacity: 0;
//   transition: 0.5s ease;
// }
// .project-arrow.safingWebsite:hover::before {
//   opacity: 1;
// }
/* End of Project Arrow Descriptions */

.project-content {
  margin-bottom: 3rem;
  .project-grid {
    padding: 5rem $side-padding;
    margin: 5rem auto;
    &:last-of-type {
      margin-bottom: 0px;
    }
    &.top {
      padding: 0 $side-padding;
      margin: 0 auto;
    }
    img {
      width: 100%;
    }
    .convert {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem 0;
    }
    img.convert-arrow {
      width: 25px;
      padding: 0 1rem;
    }
    .text {
      margin: auto 0
    }
  }
  .hero {
    margin: auto;
    text-align: center;
    padding-bottom: 2rem;
  }
  .hero img, .image-wrapper img {
    max-width: 400px;
    max-height: 800px;
  }
  .image-wrapper {
    text-align: center;
    padding-bottom: 3rem;
    margin: auto;
  }
  .skill-wrapper {
    margin: auto 0;
    .tools {
      padding-bottom: 1rem;
    }
    .tools {
      font-size: 1.125rem;
      font-weight: 600;
    }
    .tag-wrapper {
      padding-bottom: 1rem;
    }
  }
  .overview {
    padding-top: 1.5rem;
  }
}

.tag {
  font-weight: 600;
  font-size: 0.875rem;
  background-color: $light-blue-30;
  border-radius: 8px;
  padding: 0.5rem 0.875rem;
  display: inline-block;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.project-link-wrapper {
  margin: 3rem auto;
  text-align: center;
  .text-link {
    font-weight: 600;
    color: $blue;
    padding-bottom: 0.3rem;
  }
}

@media (min-width: $md) {
  .project-header {
    grid-template-columns: repeat(3,auto);
    .project-title {
      grid-column: 2/3;
      padding: 0 3rem;
    }
    .project-arrow:first-of-type {
      grid-column-start: 1;
      grid-row-start: 1;
    }
    .project-arrow:nth-of-type(2) {
      grid-column-start: 3;
    }
  }
  .project-link-wrapper {
    margin: 5rem auto;
  }
}

@media (min-width: $lg) {
  .project-content {
    .project-grid {
      grid-template-columns: repeat(2,1fr);
      display: grid;
      grid-column-gap: 3rem;
      grid-row-gap: 3rem;
      &.top {
        padding: 0;
      }
      &.safing {
        display: block;
      }
      &.safing .image-wrapper {
        padding-bottom: 3rem;
        img {
          max-width: 800px;
        }
      }
    }
    .bg-blue .project-grid .image-wrapper {
      order: 2;
    }
    .hero {
      padding-bottom: 0rem;
    }
    .image-wrapper {
      padding-bottom: 0;
    }
    .skill-wrapper {
      .tag-wrapper:last-of-type {
        padding-bottom: 0px;
      }
    }
    .overview {
      grid-column: 1/3;
      text-align: center;
      max-width: 800px;
      margin: 0 auto;
      padding-top: 0px;
    }
  }
}

@media (min-width: $xl) {
  .project-content {
    .project-grid {
      grid-column-gap: 5rem;
    }
    .hero {
      grid-row: 1/3;
    }
    .hero img, .image-wrapper img {
      max-width: 100%;
    }
    .skill-wrapper {
      margin: auto 0 0 0;
    }
    .overview {
      grid-row: 2/3;
      grid-column: 2/3;
      text-align: left;
    }
  }
  .tag {
    font-size: 1rem;
  }
}
