button:hover {
  cursor: pointer;
}

.primary-btn {
  display: inline-block;
  background-color: $blue;
  color: $white;
  border-radius: 0.75rem;
  padding: 0.375rem 1.875rem;
  font-weight: 600;
  transition: 0.5s ease;
  &:hover,
  &:focus {
    background-color: $dark-grey;
  }
  &:focus {
    box-shadow: 0 0 0 2px $light-blue-70;
  }
}


.top-btn {
  width: 42px;
  height: 42px;
  background-color: $blue;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 45px;
  right: 20px;
  box-shadow: 7px 7px 15px -7px rgba(0,0,0,0.3);
  -webkit-box-shadow: 7px 7px 15px -7px rgba(0,0,0,0.3);
  -moz-box-shadow: 7px 7px 15px -7px rgba(0,0,0,0.3);
  transition: 0.3s ease;
  &.invisible {
    opacity: 0;
  }
  &.visible {
    opacity: 0.8;
  }
  &.visible:hover,
  &.visible:focus {
    opacity: 1;
  }
  &.visible:focus {
    box-shadow: 0 0 0 2px $light-blue-70;
  }
  img {
    width: 20px;
  }
}

@media (min-width: $xl) {
  .top-btn {
    bottom: 35px;
  }
}
