/* Breakpoint Variables */
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;
/* End of Breakpoint Variables */

/* Color Variables */
$yellow: #f9bc60;
$black: #26282b;
$dark-grey: #353941;
$blue: #5575ad;
$blue-10: rgba(85,117,173,0.1);
$bg-blue: #e9eff3;
$light-blue: #90b8f8;
$light-blue-70: rgba(144,184,248,0.7);
$light-blue-30: rgba(144,184,248,0.3);
$white: #FFFFFF;
$pink: #f792b4;

$white-gradient-50: linear-gradient(0deg, rgba(255,255,255,0) 50%, rgba(255,255,255,1) 100%);
/* End of Color Variables */

/* Nav Height Variables */
$nav-height-desktop: 47px;
$min-height-desktop: 93px;

$nav-height-mobile: 71.09px;
$min-height-mobile: 121px;
/* End of Nav Height Variables */

/* Spacing Variables */
$side-padding: 1.25rem;
/* End of Spacing Variables */

/* Font Styles */
body, a, p, h1, h2, h3, h4, h5, h6, li, table, button {
  font-family: 'Open Sans', sans-serif;
  margin: 0px;
  color: $dark-grey;
}

a {
  text-decoration: none;
}

button {
  border: none;
}

a:focus, button:focus {
  outline: none;
}

h1 {
  font-size: 1.375rem;
  text-align: center;
  padding: 3rem 0;
}

h2 {
  font-size: 1.25rem;
}

p, button, a, li {
  font-size: 0.875rem;
}

p {
  line-height: 1.375;
}

/* Font Style Media Queries */
@media (min-width: $md) {
  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.375rem;
  }

  p, button, a, li {
    font-size: 1rem;
  }
}
/* End of Font Style Media Queries */

/* End of Font Styles */

/* Global Styles */
.w-100 {
  width: 100%;
}
.w-50 {
  width: 50%;
}
.d-none {
  display: none;
}
.invisible {
  visibility: hidden;
}
.opacity-100 {
  opacity: 1;
}
.opacity-0 {
  opacity: 0;
}
.text-center {
  text-align: center;
}
.paragraph {
  padding-bottom: 1rem;
  &:last-of-type {
    padding-bottom: 0px;
  }
}
.subheading {
  font-weight: 600;
  padding-bottom: 1rem;
}
.bg-blue {
  background-color: $bg-blue;
}
.divider {
  width: 100%;
  height: 8px;
  background: rgb(95,133,219);
  background: linear-gradient(45deg, rgba(95,133,219,0.3) 0%, rgba(249,188,96,0.3) 50%, rgba(247,146,180,0.3) 100%);
}

/* Spacing */
.mt-5 {
  margin-top: 5rem;
}
.mb-5 {
  margin-bottom: 5rem;
}
.mb-4 {
  margin-bottom: 4rem;
}
.mb-3 {
  margin-bottom: 3rem;
}
.mt-2 {
  margin-top: 2rem;
}
.mb-2 {
  margin-bottom: 2rem;
}
/* End of Spacing */

/* Text Link Styles */
.text-link {
  position: relative;
  color: $blue;
}

.text-link::after {
  content: "";
  display: block;
  width: 0%;
  height: 2px;
  background-color: $blue;
  transition: 0.3s ease;
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.text-link:hover::after {
  width: 100%;
}

.text-link[target="_blank"] {
  display: inline-flex;
  &::before {
    content: "";
    background-image: url("../../images/common/link-out.svg");
    width: 16px;
    height: 16px;
    display: inline-block;
    background-repeat: no-repeat;
    order: 2;
    margin-left: 0.5rem;
    opacity: 0;
    transition: 0.5s ease;
    position: relative;
  }
  &:hover::before,
  &:focus::before {
    opacity: 1;
  }
}
/* End of Text Link Styles */


/* Colors */
.white-gradient {
  background: rgb(255,255,255);
  background: $white-gradient-50;
}
/* End of Colors */


/* Display Styles */
.d-none {
  display: none;
}
@media (min-width: $lg) {
  .d-lg-block {
    display: block;
  }
}
/* End of Display Styles */

/* End of Global Styles */


/* Layout Styles */
.content-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.content-wrapper .main-content {
  flex-grow: 1;
  margin-top: $nav-height-mobile;
}

.container {
  margin: 0 auto;
}

@media (min-width: $sm) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: $md) {
  .container {
    max-width: 720px;
  }
  .content-wrapper .main-content {
    margin-top: $nav-height-desktop;
  }
}

@media (min-width: $lg) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: $xl) {
  .container {
    max-width: 1140px;
  }
}

@media (min-width: $xxl) {
  .container {
    max-width: 1320px;
  }
}
/* End of Layout Styles */


/* 404 Page Styles */
.not-found {
  display: flex;
  align-items: center;
  min-height: calc(100vh - $min-height-mobile);
  .container {
    width: 100%;
    padding: 0 $side-padding;
  }
}

@media (min-width: $md) {
  .not-found {
    min-height: calc(100vh - $min-height-desktop);
  }
}
/* End of 404 Page Styles */
