
.home-icons-wrapper {
  margin: 2rem auto;
}

.about-icon-wrapper, .works-icon-wrapper {
  height: 100%;
}

.about-icon-wrapper, .works-icon-wrapper, .archives-icon-wrapper {
  display: flex;
  padding: 1rem;
  border-radius: 20%;
  border: 1px solid $light-blue;
  width: 250px;
  height: 250px;
  margin: 2rem auto;
  transition: 0.5s ease;

  &:hover, &:focus {
    background-color: $light-blue-30;
  }

  &:focus {
    box-shadow: 0 0 0 2px $light-blue-70;
  }
}

.archives-icon-wrapper {
  width: 125px;
  height: 125px;
  margin: 2rem auto;
}

.about-icon, .works-icon, .archives-icon {
  display: flex;
}

// .archives-icon {
//   width: 100%;
// }
.about-icon svg, .works-icon svg, .archives-icon svg {
  width: 100%;
  height: 100%;
}

.about-icon-wrapper:hover .about-icon .cls-14,
.about-icon-wrapper:focus .about-icon .cls-14 {
  fill-opacity: 0.1;
}
.works-icon-wrapper:hover .works-icon .cls-72,
.works-icon-wrapper:focus .works-icon .cls-72 {
  fill: $dark-grey;
  fill-opacity: 0.6;
}
.about-icon-wrapper:hover .about-icon .cls-15,
.about-icon-wrapper:focus .about-icon .cls-15,
.works-icon-wrapper:hover .works-icon .cls-73,
.works-icon-wrapper:focus .works-icon .cls-73 {
  fill: $white;
}

@media (min-width: $md) {
  .home-icons-wrapper {
    display: grid;
    grid-template-columns: 35% 35% 20%;
    align-items: flex-end;
    justify-content: center;
    grid-column-gap: 1.5rem;
  }
  .about-icon-wrapper, .works-icon-wrapper, .archives-icon-wrapper {
    width: auto;
    height: 100%;
    margin: 0;
  }
  .archives-icon-wrapper {
    height: auto;
  }
}

@media (min-width: $lg) {
  .home-icons-wrapper {
    grid-template-columns: 30% 30% 15%;
  }
}

// SVG Styles below
/* About Icon */
.about-icon {
  .cls-1,
  .cls-2 {
    opacity: 0.15;
  }

  .cls-1 {
    fill: url(#about-linear-gradient);
  }

  .cls-2 {
    fill: url(#about-linear-gradient-2);
  }

  .cls-10,
  .cls-13,
  .cls-3 {
    fill: #353941;
  }

  .cls-3 {
    opacity: 0.1;
  }

  .cls-4 {
    fill: url(#about-linear-gradient-3);
  }

  .cls-5 {
    fill: url(#about-linear-gradient-4);
  }

  .cls-6 {
    fill: #26282b;
  }

  .cls-7 {
    fill: #b66d88;
  }

  .cls-8 {
    fill: url(#about-linear-gradient-5);
  }

  .cls-9 {
    fill: url(#about-linear-gradient-6);
  }

  .cls-11 {
    fill: #34679b;
  }

  .cls-12 {
    fill: #265175;
  }

  .cls-13 {
    stroke: #0b1b2b;
    stroke-miterlimit: 10;
  }

  .cls-14 {
    fill: #e6edf4;
    transition: 0.5s ease;
  }

  .cls-15 {
    fill: #5575ad;
    transition: 0.5s ease;
  }
}
/* End of About Icon */


/* Works Icon */
.works-icon {
  .cls-1 {
    opacity: 0.1;
    fill: url(#works-linear-gradient);
  }

  .cls-2,
  .cls-3 {
    opacity: 0.15;
  }

  .cls-2 {
    fill: url(#works-linear-gradient-2);
  }

  .cls-3 {
    fill: url(#works-linear-gradient-3);
  }

  .cls-4 {
    fill: url(#works-linear-gradient-4);
  }

  .cls-5 {
    fill: url(#works-linear-gradient-5);
  }

  .cls-6 {
    fill: url(#works-linear-gradient-6);
  }

  .cls-7 {
    fill: url(#works-linear-gradient-7);
  }

  .cls-8 {
    fill: url(#works-linear-gradient-8);
  }

  .cls-9 {
    fill: url(#works-linear-gradient-9);
  }

  .cls-10 {
    fill: url(#works-linear-gradient-10);
  }

  .cls-11 {
    fill: url(#works-linear-gradient-11);
  }

  .cls-12 {
    fill: url(#works-linear-gradient-12);
  }

  .cls-13 {
    fill: url(#works-linear-gradient-13);
  }

  .cls-14 {
    fill: url(#works-linear-gradient-14);
  }

  .cls-15 {
    fill: url(#works-linear-gradient-15);
  }

  .cls-16 {
    fill: url(#works-linear-gradient-16);
  }

  .cls-17 {
    fill: url(#works-linear-gradient-17);
  }

  .cls-18 {
    fill: url(#works-linear-gradient-18);
  }

  .cls-19 {
    fill: url(#works-linear-gradient-19);
  }

  .cls-20 {
    fill: url(#works-linear-gradient-20);
  }

  .cls-21 {
    fill: url(#works-linear-gradient-21);
  }

  .cls-22 {
    fill: url(#works-linear-gradient-22);
  }

  .cls-23 {
    fill: url(#works-linear-gradient-23);
  }

  .cls-24 {
    fill: url(#works-linear-gradient-24);
  }

  .cls-25 {
    fill: url(#works-linear-gradient-25);
  }

  .cls-26 {
    fill: url(#works-linear-gradient-26);
  }

  .cls-27 {
    fill: url(#works-linear-gradient-27);
  }

  .cls-28 {
    fill: url(#works-linear-gradient-28);
  }

  .cls-29 {
    fill: url(#works-linear-gradient-29);
  }

  .cls-30 {
    fill: url(#works-linear-gradient-30);
  }

  .cls-31 {
    fill: url(#works-linear-gradient-31);
  }

  .cls-32 {
    fill: url(#works-linear-gradient-32);
  }

  .cls-33 {
    fill: url(#works-linear-gradient-33);
  }

  .cls-34 {
    fill: url(#works-linear-gradient-34);
  }

  .cls-35 {
    fill: url(#works-linear-gradient-35);
  }

  .cls-36 {
    fill: url(#works-linear-gradient-36);
  }

  .cls-37 {
    fill: url(#works-linear-gradient-37);
  }

  .cls-38 {
    fill: url(#works-linear-gradient-38);
  }

  .cls-39 {
    fill: url(#works-linear-gradient-39);
  }

  .cls-40 {
    fill: url(#works-linear-gradient-40);
  }

  .cls-41 {
    fill: url(#works-linear-gradient-41);
  }

  .cls-42 {
    fill: url(#works-linear-gradient-42);
  }

  .cls-43 {
    fill: url(#works-linear-gradient-43);
  }

  .cls-44 {
    fill: url(#works-linear-gradient-44);
  }

  .cls-45 {
    fill: url(#works-linear-gradient-45);
  }

  .cls-46 {
    fill: url(#works-linear-gradient-46);
  }

  .cls-47 {
    fill: url(#works-linear-gradient-47);
  }

  .cls-48 {
    fill: url(#works-linear-gradient-48);
  }

  .cls-49 {
    fill: url(#works-linear-gradient-49);
  }

  .cls-50 {
    fill: url(#works-linear-gradient-50);
  }

  .cls-51 {
    fill: url(#works-linear-gradient-51);
  }

  .cls-52 {
    fill: url(#works-linear-gradient-52);
  }

  .cls-53 {
    fill: url(#works-linear-gradient-53);
  }

  .cls-54 {
    fill: url(#works-linear-gradient-54);
  }

  .cls-55 {
    fill: url(#works-linear-gradient-55);
  }

  .cls-56 {
    fill: url(#works-linear-gradient-56);
  }

  .cls-57 {
    fill: url(#works-linear-gradient-57);
  }

  .cls-58 {
    fill: url(#works-linear-gradient-58);
  }

  .cls-59 {
    fill: url(#works-linear-gradient-59);
  }

  .cls-60 {
    fill: url(#works-linear-gradient-60);
  }

  .cls-61 {
    fill: url(#works-linear-gradient-61);
  }

  .cls-62 {
    fill: url(#works-linear-gradient-62);
  }

  .cls-63 {
    fill: url(#works-linear-gradient-63);
  }

  .cls-64 {
    fill: url(#works-linear-gradient-64);
  }

  .cls-65 {
    fill: url(#works-linear-gradient-65);
  }

  .cls-66 {
    fill: url(#works-linear-gradient-66);
  }

  .cls-67,
  .cls-68 {
    fill: #90b8f8;
  }

  .cls-68 {
    opacity: 0.3;
  }

  .cls-69 {
    fill: url(#works-linear-gradient-67);
  }

  .cls-70 {
    fill: url(#works-linear-gradient-68);
  }

  .cls-71 {
    fill: url(#works-linear-gradient-69);
  }

  .cls-72 {
    fill: #fffffe;
    transition: 0.5s ease;
  }

  .cls-73 {
    fill: #5575ad;
    transition: 0.5s ease;
  }
}
/* End of Works Icon */
