.header {
  position: fixed;
  width: 100%;
  top: 0;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 0.75rem $side-padding;
  z-index: 999;
  .logo .name {
    font-weight: 600;
    font-size: 1.25rem;
    padding-bottom: 0.5rem;
  }
}

.breadcrumb-wrapper {
  padding: 1rem $side-padding;
  .breadcrumb-link {
    position: relative;
    color: $blue;
    padding-bottom: 0.3rem;
  }
  .breadcrumb-link::after {
    content: "";
    display: block;
    width: 0%;
    height: 2px;
    background-color: $blue;
    transition: 0.3s ease;
    position: absolute;
    bottom: 0px;
    left: 0px;
  }
  .breadcrumb-link:hover::after {
    width: 100%;
  }
  .slash {
    padding: 0 0.5rem;
  }
}

.footer {
  text-align: center;
  padding: 0.75rem $side-padding;
}

@media (min-width: $sm) {
  .header {
    padding: 0.75rem 0rem;
  }
}

@media (min-width: $md) {
  .header .logo {
    display: inline-flex;
    align-items: flex-end;
    .name {
      padding-bottom: 0px;
    }
    .bar {
      width: 1px;
      height: 23px;
      background-color: $dark-grey;
      margin: 0 0.75rem;
    }
  }
  .footer {
    text-align: right;
  }
}
