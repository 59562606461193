.tab-list {
  list-style: none;
  padding: 0px;
  margin: 0px;
  display: grid;
  grid-template-columns: repeat(2,1fr);
}

.websites-tab, .apps-tab {
  text-align: center;
  transition: 0.5s ease;
  & button {
    background-color: transparent;
    padding: 2.5rem 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    height: 100%;
    justify-content: center;
  }
  p {
    color: $blue;
    font-weight: 600;
    font-size: 1.25rem;
    display: inline-block;
    position: relative;
    padding-bottom: 0.3rem;
  }
  p::after {
    content: "";
    display: block;
    width: 0%;
    height: 2px;
    background-color: $blue;
    transition: 0.3s ease;
    position: absolute;
    bottom: 0px;
    left: 0px;
  }
  &:hover,
  &:hover button {
    cursor: pointer;
  }
  &:hover p::after,
  &:focus p::after,
  &.active p::after {
    width: 100%;
  }
}

.websites-tab {
  img {
    width: 100%;
    max-width: 250px;
    height: 100px;
  }
  &.active {
    background-color: #f1ecef;
  }
}

.apps-tab {
  img {
    width: 100%;
    max-width: 190px;
    height: 100px;
  }
  &.active {
    background-color: #c7d0dc;
  }
}

.websites-panel {
  background: rgb(249,234,235);
  background: linear-gradient(-120deg, rgba(249,234,235,0.7) 0%, rgba(233,239,243,0.7) 100%);
}

.apps-panel {
  background: rgb(154,174,204);
  background: linear-gradient(-120deg, rgba(154,174,204,0.7) 0%, rgba(242,241,235,0.7) 100%);
}

.websites-panel, .apps-panel {
  display: none;
  visibility: hidden;
  grid-template-columns: repeat(1,1fr);
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  padding: 2rem;
  margin-bottom: 1.5rem;
  img {
    width: 100%;
  }
  .selection {
    display: block;
    text-align: center;
    border-radius: 20px;
    padding: 1.5rem;
    transition: 0.5s ease;
    p {
      font-size: 1.125rem;
      font-weight: 600;
    }
  }
  .selection:hover,
  .selection:focus {
    background-color: $blue-10;
  }

  &.active {
    display: grid;
    visibility: visible;
  }
}

.apps-panel {
  grid-template-columns: repeat(1, 1fr);
  img {
    max-width: 180px;
    padding-bottom: 1rem;
  }
}

@media (min-width: $sm) {
  .websites-tab  button, .apps-tab button {
    width: 250px;
  }
}

@media (min-width: $md) {
  .websites-panel {
    grid-template-columns: repeat(2,1fr);
  }
  .websites-tab {
    img {
      max-width: 250px;
      height: auto;
    }
  }
  .apps-tab {
    img {
      max-width: 190px;
      height: auto;
    }
  }
  .apps-panel {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: $lg) {
  .websites-panel {
    grid-template-columns: repeat(3,1fr);
  }
  .websites-tab  button, .apps-tab button {
    width: 378px;
  }
  .websites-tab {
    img {
      max-width: 378px;
    }
  }
  .apps-tab {
    img {
      max-width: 230px;
    }
  }
  .apps-panel {
    grid-template-columns: repeat(5, 1fr);
    .selection:first-of-type {
      grid-column-start: 2;
    }
  }
}
