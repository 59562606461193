h1.archives {
  padding-bottom: 2rem;
}

.archives-content {
  img {
    width: 100%;
  }
  .archives-image-wrapper {
    padding: 0 $side-padding;
    margin: 2rem auto;
    display: grid;
    grid-row-gap: 2rem;
    img.pyro, img.butterfly {
      max-width: 300px;
      margin: 0 auto;
    }
  }
  .blackbarrett img:first-of-type {
    margin-bottom: 2rem;
  }

  .traditional-image-wrapper {
    display: grid;
    grid-template-columns: repeat(3,auto);
  }
}


@media (min-width: $md) {
  .archives-content {
    .blackbarrett {
      text-align: right;
    }
    .blackbarrett img,
    img.dephy {
      width: 90.5%;
      margin-left: auto;
    }
    .blackbarrett img:first-of-type {
      margin-bottom: 0;
    }
    .archives-image-wrapper {
      max-width: 1000px;
      grid-template-columns: repeat(2,auto);
      img.large {
        width: 115%;
      }
      img.drawn-shoe,
      img.pyro,
      img.butterfly {
        width: 75%;
        margin: auto 0 auto auto;
      }
      img.pyro {
        width: 70%;
        max-width: none;
      }
      img.butterfly {
        width: 53%;
        max-width: none;
        position: relative;
        right: 3rem;
      }
    }
  }
}
