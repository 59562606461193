
.home-bg {
  background-color: rgba(255,255,255,0.5);
}

.home-wrapper {
  min-height: calc(100vh - 113.5px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (min-width: $lg) {
  .home-wrapper {
    min-height: calc(100vh - 90px);
  }
}
