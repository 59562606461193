.landing-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing-bg {
  min-height: 100vh;
  background-image: url("../../images/landing/bg-mobile.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
}

.landing-text-wrapper {
  text-align: center;
}

.landing-wrapper h1 {
  font-size: 1.6rem;
  padding-bottom: 1rem;
}

.landing-wrapper p {
  font-size: 1.375rem;
  padding-bottom: 1rem;
}

.gradient {
  position: relative;
  overflow: hidden;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gradient::after {
  content: "";
  display: block;
  position: absolute;
  background: rgb(95, 133, 219);
  background: linear-gradient(0deg, rgba(95, 133, 219, 0.7) 0%, rgba(249, 188, 96, 0.7) 20%, rgba(247, 146, 180, 0.7) 50%, rgba(95, 133, 219, 0.7) 70%);
  width: calc(100vw*3);
  height: calc(100vh*3);
  z-index: -1;
}

.gradient.animated::after {
  animation: rotate linear 3s infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
    transform-origin: center;
  }
  100% {
    transform: rotate(360deg);
    transform-origin: center;
  }
}

@media (min-width: $lg) {
  .landing-bg {
    background-image: url("../../images/landing/bg-desktop.svg");
  }
}
