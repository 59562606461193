
.about-info {
  max-width: 1000px;
  margin: 0 auto 3rem auto;
  text-align: center;
  padding: 0 $side-padding;
}

.about-category-wrapper {
  display: grid;
  justify-content: center;
  grid-row-gap: 3rem;
  margin-bottom: 5rem;
}

.about-category {
  text-align: center;
  .hobbies, .music, .games {
    height: 245px;
    width: 245px;
    border: 1px solid $light-blue;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 1rem;
  }
  .hobbies img {
    height: 196px;
  }
  .music img {
    height: 205px;
  }
  .games img {
    height: 175px;
  }

  .about-category-title {
    font-weight: 600;
    color: $blue;
    font-size: 1.25rem;
    padding-bottom: 0.75rem;
  }

  .about-category-title,
  .about-category-list {
    text-align: center;
  }

  .about-category-list {
    line-height: 1.5;
  }
}

@media (min-width: $lg) {
  .about-category-wrapper {
    grid-template-columns: repeat(3,1fr);
    grid-column-gap: 1.5rem;
  }
  .about-category {
    .about-category-title,
    .about-category-list {
      text-align: left;
      width: 245px;
      margin: 0 auto;
    }
    .hobbies, .music, .games {
      margin-bottom: 2rem;
    }
  }
}
